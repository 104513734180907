html {
	font-size: 62.5%;	
}
body {
	margin: 0;
	font-size: 1.4rem;
	font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.grow {
	flex-grow: 1;
}

.MuiSkeleton-root {
	background-color: #0000440F !important;
}